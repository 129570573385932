import { BaseReadingApi } from './api.base';
import { apiNet as api } from '@cems-eco/core/src/services/api';

export class CloudReadingApi extends BaseReadingApi {
    async allByEquipment(equipmentId: any): Promise<any> {
        const url = this.BASE_URL + '/equipment/' + equipmentId + '/30m';
        return await api.get<any>(url, true);
    }

    async getOneMinBy30min(sensor_id: string, timestamp: string): Promise<any> {
        const url = this.BASE_URL + '/by30min/' + sensor_id + '/' + timestamp;
        return await api.get<any>(url, true);
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

}
