import { Client, Equipment, Maintenance, Site, User } from "./index"
import maintenanceApi from "./api"
import { ref } from "vue"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils"
import storage from "@/storage"
import { ISiteStorage } from "@/storage/model"
import { Observable } from "rxjs"
import { startWith } from 'rxjs/operators';

export abstract class BaseMaintenanceBloc {
    module = 'maintenance';
    api = maintenanceApi;
    base_url = environment.API_URL + '/' + module;

    async getMaintenances(): Promise<[Maintenance]> {
        let response = await this.api.all()
        return response.data.data;
    }

    async getMaintenance(id: any): Promise<Maintenance> {
        let response = await this.api.first(id)
        return response.data;
    }

    async createMaintenance(maintenance: Maintenance): Promise<Maintenance> {
        let response = await this.api.create(maintenance)
        return response.data;
    }

    async storeRelation(maintenance: Maintenance): Promise<Maintenance> {
        let response = await this.api.storeRelation(maintenance)
        return response.data;
    }

    async serialNumber(): Promise<Maintenance> {
        let response = await this.api.serialNumber()
        return response.data;
    }

    async updateRelation(maintenance: Maintenance): Promise<Maintenance> {
        let response = await this.api.updateRelation(maintenance.id, maintenance)
        return response.data;
    }

    async downloadFile(fileName: string, id: any): Promise<void> {
        await this.api.download(fileName, id);
    }

    async deleteRelation(id: any): Promise<void> {
        let response = await this.api.deleteRelation(id)
        return response.data;
    }

    async updateMaintenance(maintenance: Maintenance): Promise<Maintenance> {
        let response = await this.api.edit(maintenance.id, maintenance)
        return response.data;
    }

    // FIXME: not working
    async destroyMaintenance(id: any): Promise<void> {
        let response = await this.api.destroy(id)
        return response.data;
    }

    async searchParams(maintenance: Maintenance): Promise<string> {
        const uri = helper.toSerializeDatatable(maintenance);
        return await this.api.getUrl(`/${this.module}${encodeURI(uri)}`);
    }

    async getClient(id: any): Promise<Client> {
        let response = await this.api.clientFirst(id)
        return response.data;
    }

    async getSite(id: any): Promise<Site> {
        let response = await this.api.siteFirst(id)
        return response.data;
    }

    async getEquipment(id: any): Promise<Equipment> {
        let response = await this.api.equipmentFirst(id)
        return response.data;
    }

    async getEquipmentSelect2BySite(site_id: any): Promise<Equipment[]> {
        let response = await this.api.equipmentSelect2BySite(site_id)
        return response.data;
    }

    async getUser(id: any): Promise<User> {
        let response = await this.api.userFirst(id)
        return response.data;
    }

    async getSiteStorage(): Promise<Observable<ISiteStorage>> {
        const siteStorage: ISiteStorage = await storage.get<ISiteStorage>(storage.SITE);
        return storage.observable<ISiteStorage>(storage.SITE).pipe(startWith(siteStorage));
    }

    async getMaintenanceUrlBySite(): Promise<string> {
        const site = await storage.get<ISiteStorage>(storage.SITE);
        return await this.api.getUrl(`/${this.module}/site/${site.id}`);
    }

    async getUserSelect2Url(): Promise<string> {
        return await this.api.getUrl('/user/select2');
    }

    async getEquipmentSelect2Url(): Promise<string> {
        return await this.api.getUrl('/equipment/select2');
    }

    async getClientSelect2Url(): Promise<string> {
        return await this.api.getUrl('/client/select2');
    }

    async getSiteSelect2Url(): Promise<string> {
        return await this.api.getUrl('/site/select2');
    }

    async getJobSelect2Url(): Promise<string> {
        return await this.api.getUrl('/reference/select2/type-of-job');
    }

    async getClientOptions(): Promise<any[]> {
        return await this.api.getOptions('/client/select2');
    }

    async getSiteOptions(): Promise<any[]> {
        return await this.api.getOptions('/site/select2');
    }

    async getUserOptions(): Promise<any[]> {
        return await this.api.getOptions('/user/select2');
    }

    async getEquipmentOptionsBySite(id: any): Promise<any[]> {
        return await this.api.getOptions(`/equipment/select2/site/${id}`);
    }

    async getJobOptions(): Promise<any[]> {
        return await this.api.getOptions('/reference/select2/type-of-job', 'text');
    }
}

