
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import BoxChartComponent from "./chart.box";
import CloudChartComponent from "./chart.cloud";
import DemoChartComponent from "./chart.demo";

export default class Web extends platform.mixins(environment.nexus, {
  box: BoxChartComponent,
  cloud: CloudChartComponent,
  demo: DemoChartComponent,
}) { }
