import { ReportFilter } from "../../services";
import reportBloc from "../../services/bloc";
import { Vue } from 'vue-class-component';
import moment from "moment";
import { cloneDeep } from "lodash";


export abstract class ReportFilterComponent extends Vue {
    reportBloc = reportBloc;
    reportFilter = new ReportFilter;
    prevReportFilter: any;
    equipmentOptions: any[] = [];
    typeOptions: any[] = [];
    equipmentLoading = false;
    typeLoading = false;
    startDatetime = null;
    endDatetime = null;

    isReportFilterEmpty() {
        return this.reportFilter.isEmpty();
    }

    searching() {
        const old = this.reportFilter;
        const data = cloneDeep(old);
        if(data != this.prevReportFilter) {
            this.prevReportFilter = data;
            this.reset();
            this.reportFilter = old;
        }
        this.$emit("filter", data);
    }

    reset() {
        this.reportFilter = new ReportFilter;
        this.startDatetime = null;
        this.endDatetime = null;
        const data = cloneDeep(this.reportFilter);
        this.$emit("filter", data);
    }

    startDateDisabled(ts: number) {
        const date = new Date(ts);
        return moment().isBefore(date, 'day');
    }

    startTimeDisabled(ts: number) {
        const date = new Date(ts);
        return {
            isHourDisabled: (hour: number) => {
                if (!moment().isSame(date, 'day')) return false;
                return hour > new Date().getHours();
            },
            isMinuteDisabled: (minute: number, hour: number | null) => {
                if (hour === null) return false;
                if (!moment().isSame(date, 'day')) return false;
                return minute > new Date().getMinutes();
            },
        }
    }

    endDateDisabled(ts: number) {
        const date = new Date(ts);
        if (moment().isBefore(date, 'day')) return true;
        return moment(this.reportFilter.start_date).isAfter(date, 'day');
    }

    endTimeDisabled(ts: number) {
        const date = new Date(ts);
        return {
            isHourDisabled: (hour: number) => {
                if (moment().isSame(date, 'day') && hour > new Date().getHours()) return true;
                if (moment(this.reportFilter.start_date).isBefore(date, 'day')) return false;
                return hour < new Date(this.reportFilter.start_date).getHours();
            },
            isMinuteDisabled: (minute: number, hour: number | null) => {
                if (hour === null) return false;
                if (moment().isSame(date, 'day') && minute > new Date().getMinutes()) return true;
                if (moment(this.reportFilter.start_date).isBefore(date, 'day')) return false;
                return minute <= new Date(this.reportFilter.start_date).getMinutes();
            },
        }
    }

    onStartChanged() {
        this.reportFilter.start_date = moment(this.startDatetime ?? '').format('YYYY-MM-DD');
        this.reportFilter.start_time = moment(this.startDatetime ?? '').format('HH:mm');

        const initial = new ReportFilter;
        this.reportFilter.end_date = initial.end_date;
        this.endDatetime = null;
    }

    onEndChanged() {
        this.reportFilter.end_date = moment(this.endDatetime ?? '').format('YYYY-MM-DD');
        this.reportFilter.end_time = moment(this.endDatetime ?? '').format('HH:mm');
    }

    async onEquipmentSearch(query: string) { }

    async onTypeSearch(query: string) { }
}