import { MaintenanceFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { debounce } from "lodash";
import { Options } from 'vue-class-component';
import storage from '@/storage';
import { Subscription } from 'rxjs';


@Options({
  emits: ['filter']
})
export default class CloudFilterComponent extends MaintenanceFilterComponent {
  subscription: Subscription;

  headers: any = {
    responseType: "json",
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  async mounted() {
    const loader = ElLoading.service({ lock: true });

    await this.getSite();

    this.userOptions = await this.maintenanceBloc.getUserOptions();
    this.jobOptions = await this.maintenanceBloc.getJobOptions();

    loader.close();
  }

  beforeUnmount() {
    this.subscription?.unsubscribe();
  }

  private async getSite() {
    let auth = storage.getSync<any>(storage.SITE_AUTH);
    if (auth) {
      this.headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
    const siteStorage = await this.maintenanceBloc.getSiteStorage();

    this.subscription = siteStorage.subscribe(async (site) => {
      if (Object.keys(site).length) {
        this.maintenance.site_id = site.id;
        this.equipmentOptions = await this.maintenanceBloc.getEquipmentOptionsBySite(this.maintenance.site_id);
      }
    });
  }

  async onUserSearch(query: string) {
    this.userLoading = true;
    debounce(async () => {
      this.userOptions = await this.maintenanceBloc.getUserOptions();
      this.userOptions = this.userOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.equipmentLoading = false;
  }

  async onEquipmentSearch(query: string) {
    this.equipmentLoading = true;
    debounce(async () => {
      this.equipmentOptions = await this.maintenanceBloc.getEquipmentSelect2BySite(this.maintenance.site_id);
      this.equipmentOptions = this.equipmentOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.equipmentLoading = false;
  }


  async onJobSearch(query: string) {
    this.jobLoading = true;
    await debounce(async () => {
      this.jobOptions = await this.maintenanceBloc.getJobOptions();
      this.jobOptions = this.jobOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.jobLoading = false;
  }
}