import { ChartConfiguration } from "@cems-eco/core/src/models";
import reportBloc from "../../services/bloc";
import { ReportFilter } from "../../services";
import formatter from "@cems-eco/core/src/services/formatter/formatter";
import { helper, platform } from "@cems-eco/core/src/utils";
import { Vue } from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import { ElLoading } from "element-plus";
import moment from "moment";


export abstract class ChartComponent extends Vue {
  unsupportTypes = [
    'JAS'
  ];

  reportBloc = reportBloc
  config: ChartConfiguration = new ChartConfiguration;
  datasets: Array<any> = [];
  labels: Array<any> = [];
  active = false;
  sensors: any[] = [];
  equipmentId: any;
  // xValueMax = 20;
  reportFilter: ReportFilter
  tableData: any = [];
  links: any = [];
  totalSensors: number = 10;
  multiplication: number = platform.factory({
    web: 20,
    mobile: 5,
  });

  async created() {
    this.setChartConfig();
  }

  async mounted() {
    // this.equipmentId = this.$route.params.id;  
    this.fetch(this.reportFilter);
  }

  setChartConfig() {
    this.config = {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },

      // Configuration options go here
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
        },
        layout: {
          padding: {
            left: -5,
            right: 5,
            // top: 0,
            // bottom: 0
          }
        },
        legend: {
          position: 'bottom',
        },
        plugins: {
          filler: {
            propagate: true
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              display: false,
              // fontColor: "#BEBEBE",
              // fontSize: 13,
              // padding: 12
              // stepSize: 1,
              // beginAtZero: true
            },
            gridLines: {
              display: false,
              // zeroLineColor: '#FFFFFF',
            },
          }],
          xAxes: [{
            position: 'top',
            ticks: {
              display: false,
              // fontColor: "#BEBEBE",
              // fontSize: 13,
              // stepSize: 1,
              // autoSkip: true,
              // beginAtZero: true
            },
            gridLines: {
              // display: false,
              borderDash: [8, 4],
              zeroLineBorderDash: [8, 4],
              zeroLineColor: '#e8e8e8',
              drawBorder: false,
            },
            // type: 'time',
          }]
        }
      },
    };
  }

  abstract fetch(reportFilter: ReportFilter, additional?: any);

  updateSensor(data: any) {
    this.sensors = [...new Set(data.flatMap((d) => d.name))].map((name) => ({
      name: name,
      unit: data.find((d) => d.name == name)?.unit,
      value: Number(data.reverse().find((d) => d.name == name)?.value).toFixed(2),
    }));
  }

  updateLabel(reportFilter: ReportFilter) {
    this.config.data = {
      labels: this.labels.map((l) => {
        const dateHeader = ["Daily", "Monthly", "Weekly", "Annually"];

        return dateHeader.includes(reportFilter.type) ? l : moment(String(l)).format('D-MM-Y h:mm');
      }),
      datasets: this.datasets,
    };
  }

  updateDataset(sensors: any, data: any) {
    this.datasets = sensors.map((sensor) => ({
      id: sensor.id,
      label: sensor.name,
      borderColor: helper.getRandomColor(4),
      fill: false,
      labels: JSON.parse(JSON.stringify(this.labels)),
      data: this.labels.length < 1 ? [] : this.labels
        .map((created_at) => {
          const r = data.find((d) => d.created_at == created_at && d.sensor_id == sensor.id);
          return (r != undefined) ? r.value : 0;
        })
        .filter((d) => d != null),
    }));
  }

  async fetchTable(data: any) {
    const loader = ElLoading.service({ lock: true });

    try {
      this.tableData = data;//await this.reportBloc.getReadings(reportFilter, ReportFormat.Table);
      this.links = this.tableData.links;
    } catch (error) {
      console.log(error);
    }

    loader.close();
  }

  async changePage(url: any) {
    var pageUrl = new URL(url);
    var page: any = pageUrl.searchParams.get("page");
    const additional = {
      length: this.totalSensors,
      start: parseInt(page.toString()) * this.totalSensors - 10
    }
    this.fetch(this.reportFilter, additional)
  }

  option(): ChartConfiguration {
    return this.config;
  }

  updateConfig() {
    this.config.data = {
      datasets: JSON.parse(JSON.stringify(this.datasets)),
      labels: JSON.parse(JSON.stringify(this.labels)),
    };

  }


  @Watch("reportFilter", { immediate: false, deep: true })
  async optionChanged(
    value: ReportFilter,
    oldValue: ReportFilter
  ) {
    if (!value) return;

    this.fetch(value);
  }


}