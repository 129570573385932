import { api } from '@cems-eco/core/src/services/api';

export abstract class BaseMonitorApi {

    async logAll(equipmentId: any): Promise<any> {
        return await api.get<any>(`/log/equipment/${equipmentId}`, true);
    }

    async getEquipment(id: string): Promise<any> {
        return await api.get<any>(`/equipment/${id}`, true);
    }

    async getEquipments(): Promise<any> {
        return await api.get<any>('/equipment?length=20', true);
    }

    async searchParams(data: any): Promise<any> {
        return api.toSerialize(data);
    }

    async byEquipment(equipment_id: any): Promise<any> {
        return await api.get<any>(`/sensor/select2/equipment/${equipment_id}`, true);
    }

    async getUrl(url): Promise<string> {
        return await api.getUrl(url);
    }

}
