import { ElLoading } from "element-plus";
import { ManagementDetailComponent } from "./details";


export class DemoManagementDetailComponent extends ManagementDetailComponent {
    private clients = [
        {
            label: "ADA.IR SDN BHD",
            value: "client",
        },
    ];

    mounted(): void {
        this.modelValue = {
            _method: '',
            "id": "f94bae84-9855-4ee5-8e3d-c76066babb1f",
            "client_id": "client",
            "contract_expiry": "2024-04-16",
            "contract_value": 2000,
            "installation_date": "2024-03-31",
            "service_contract_value": 50,
            "additional": '',
        }
        this.clientOptions = this.clients;
        this.isLoading = false;
    }
}