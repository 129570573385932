import { Document, UploadRequest } from "./index";
import documentApi from "./api";
import { helper } from "@cems-eco/core/src/utils"

export abstract class BaseDocumentBloc {
    module = 'document';
    api = documentApi
    abstract base_url;

    async getDocuments() : Promise<[Document]> {
        let response = await this.api.all()
        return response.data.data;
    }

    async getDocument(id: any): Promise<Document> {
        let response = await this.api.first(id)
        return response.data;
    }

    async createDocument(document: Document): Promise<Document> {
        let response = await this.api.create(document)
        return response.data;
    }

    async updateDocument(document: Document): Promise<Document> {
        let response = await this.api.edit(document.id, document)
        return response.data;
    }

    // FIXME: not working
    async destroyDocument(id: any): Promise<void> {
        let response = await this.api.destroy(id)
        return response.data;
    }

    async uploadDocument(document: UploadRequest): Promise<Document> {
        let response = await this.api.upload(document)
        return response.data;
    }
}

