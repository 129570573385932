
import { platform } from "@cems-eco/core/src/utils";

import MobileTableComponent from "./table/table.mobile.vue";
import WebTableComponent from "./table/table.web.vue";

import MobileChartComponent from "./chart/chart.mobile.vue";
import WebChartComponent from "./chart/chart.web.vue";

import MobileFilterComponent from "./filter/filter.mobile.vue";
import WebFilterComponent from "./filter/filter.web.vue";


export default platform.factory({
    mobile: {
        TableComponent: MobileTableComponent,
        ChartComponent: MobileChartComponent,
        FilterComponent: MobileFilterComponent,
    },
    web: {
        TableComponent: WebTableComponent,
        ChartComponent: WebChartComponent,
        FilterComponent: WebFilterComponent,
    },
});
