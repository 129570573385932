
import reportBloc from "../../services/bloc";
import { ReportFilter, ReportFormat } from "../../services";
import { Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import moment from "moment";
import { ElLoading } from "element-plus";
import formatter from "@cems-eco/core/src/services/formatter/formatter";
import { widget } from "@cems-eco/core/src/utils";

export abstract class ReportTableComponent extends Vue {

    reportBloc = reportBloc
    logs: object[] = []
    options = {}
    reportFilter: ReportFilter
    header: any = {
        label: [
            "Name",
            "Equipment",
            "Value",
            "Date"
        ],
        data: [
            "name",
            "equipment_name",
            "value",
            "created_at"
        ],
    }
    headers: any = {
        responseType: "json",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
    isLoading = false;

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        this.isLoading = true;

        // this.equipmentId = this.$route.params.id;
        this.updateHeaders();
        await this.builDataTable();

        this.isLoading = false;
        loader.close();
    }

    updateHeaders() { };

    mappingColumns() {
        if (!Object.keys(this.header).length) return [];

        return this.header.label.map((item, index) => ({
            title: item,
            data: this.header.data[index],
            mobileView: item.toLocaleLowerCase() != 'name' && item.toLocaleLowerCase() != 'stack',
            render: function (data, type, row) {
                if (!moment(data, moment.ISO_8601, true).isValid()) return data;
                return moment(data).format('DD/MM/YYYY h:mm:ss');
            }
        }));
    }

    @Watch("reportFilter", { immediate: false, deep: true })
    async optionChanged(
        value: ReportFilter,
        oldValue: ReportFilter
    ) {
        if (!value) return;

        await this.builDataTable();
    }

    async builDataTable() {
        this.header = await this.reportBloc.getHeaderReport(this.reportFilter);
        const searchUrl = await this.reportBloc.searchParams(this.reportFilter, ReportFormat.Table);

        const columns = this.mappingColumns();
        this.options = {
            serverSide: true,
            ajax: {
                url: searchUrl,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers: this.headers,
            },
            mobileView: {
                header: {
                    title: function (row: any) {
                        if (searchUrl.split('=').at(-1)?.toLocaleLowerCase() == 'jas') return row['stack'];
                        return row['name'];
                    },
                }
            },
            buttons: [
                {
                    extend: 'collection',
                    text: '<span class="fas fa-file-download pr-2" tooltip data-title="Download"></span>',
                    buttons: [
                        {
                            text: 'CSV',
                            action: async (_) => this.downloadFile(ReportFormat.CSV),
                        },
                        {
                            text: 'PDF',
                            action: async (_) => this.downloadFile(ReportFormat.PDF),
                        },
                    ],
                },
            ],
            columnDefs: [
                {
                    "targets": -1,
                    "width": "1%"
                },
            ],
            columns,
        };
    }

    async downloadFile(format: ReportFormat) {
        const loader = ElLoading.service({ lock: true });
        try {
            const now = new Date();
            const date = formatter.custom(now, 'Y.M.D');
            await this.reportBloc.downloadFile(this.reportFilter, format, `report-${date}.${format}`);
            loader.close();
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }
}