import { Vue } from "vue-class-component";
import { Options } from 'vue-class-component';
import { PartSupplied } from "../../services";


@Options({
    props: ['modelValue', 'viewOnly'],
    emits: ['update:modelValue']
})
export default class MobileMaintenancePartComponent extends Vue {
    modelValue: any;

    addRow(e: any) {
        e.preventDefault();
        if (!this.modelValue) {
            this.modelValue = [];
        }
        this.modelValue.push(new PartSupplied);
    }

    removeRow(index: number, e: any) {
        e.preventDefault();
        this.modelValue.splice(index, 1);
    }
}