import { BoxLogBloc } from "./bloc.box";
import { CloudLogBloc } from "./bloc.cloud";
import { platform } from "@cems-eco/core/src/utils";
import { BaseLogBloc } from "./bloc.base";
import environment from "@/config";

export default platform.service<BaseLogBloc>(environment.nexus, {
    box: new BoxLogBloc(),
    cloud: new CloudLogBloc(),
    demo: new CloudLogBloc(),
});