import { ElLoading } from "element-plus";
import { MaintenanceDocumentComponent } from "./document";


export class DemoMaintenanceDocumentComponent extends MaintenanceDocumentComponent {

    mounted(): void {
        // const loader = ElLoading.service({ lock: true });


        // loader.close();
    }
}