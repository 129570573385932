import { ReportFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { debounce } from "lodash";
import { Options } from 'vue-class-component';


@Options({
  emits: ['filter']
})
export default class CloudFilterComponent extends ReportFilterComponent {
  async mounted() {
    const loader = ElLoading.service({ lock: true });
    await this.buildEquipmentSelect();
    await this.buildReadingTypeSelect();
    loader.close();
  }

  async buildEquipmentSelect() {
    this.equipmentOptions = await this.reportBloc.getEquipmentOptions();
  }

  async buildReadingTypeSelect() {
    this.typeOptions = await this.reportBloc.getReadingTypeOptions();
  }

  async onEquipmentSearch(query: string) {
    this.equipmentLoading = true;
    debounce(async () => {
      await this.buildEquipmentSelect();
      this.equipmentOptions = this.equipmentOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.equipmentLoading = false;
  }


  async onTypeSearch(query: string) {
    this.typeLoading = true;
    await debounce(async () => {
      await this.buildReadingTypeSelect();
      this.typeOptions = this.typeOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.typeLoading = false;
  }
}