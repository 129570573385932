
import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

import { BoxManagementDetailComponent } from "./details.box";
import { CloudManagementDetailComponent } from "./details.cloud";
import { DemoManagementDetailComponent } from "./details.demo";

export default class Details extends platform.mixins(environment.nexus, {
    box: BoxManagementDetailComponent,
    cloud: CloudManagementDetailComponent,
    demo: DemoManagementDetailComponent,
}) { }
