export class Client {
    public id!: any;
    public _method!: string;
	public name!: string;
	public additional!: string;
	public status!: string;
	public contact!: string;
	public address!: string;
	public tel_fax!: string;
	public end_user!: string;
}


export class Site {
    public id!: any;
    public _method!: string;
	public client_id!: string;
	public name!: string;
	public additional!: string;
    public host!: string;
 
}

export class Equipment {
    public id!: any;
    public _method!: string;
    public client_id!: string;
    public site_id!: string;
    public name!: string;
    public manufacturer!: string;
    public model!: string;
    public date_manufactured!: string;
    public serial_number!: string;
    public additional!: string;
}

export class User {
    public id!: any;
    public _method!: string;
	public name!: string;
	public email!: string;
	public role!: string;
	public password!: string;
	public confirmPassword!: string;
	public additional!: string;
}

export interface IMaintenance {
    readonly id: any;
    name: string
    description: string
}

export class Maintenance {
    public id!: any;
    public _method!: string;
    public client_id!: string;
    public site_id!: string;
    public equipment_id!: string;
    public user_id!: string;
    public name!: string;
    public diagnosed!: string;
    public remarks!: string;
    public progress!: string;
    public part_supplied!: Array<PartSupplied>;
    public start_date!: string;
    public end_date!: string;
    public start_time!: string;
    public end_time!: string;
    public additional!: string;
    public serial_number!:string;
    public tag_number!:string;
    public next_service_reminder!:string;
}

export class PartSupplied {
    public part_number: string;
    public description: string;
    public quantity: number;
    public remarks: string;
}