
import { Vue } from 'vue-class-component';
import maintenanceBloc from "../../services/bloc";
import { Maintenance } from '../../services';
import { Subscription } from 'rxjs';


export abstract class MaintenanceFilterComponent extends Vue {
    maintenanceBloc = maintenanceBloc;
    maintenance = new Maintenance;

    userOptions: any = null;
    equipmentOptions: any = null;
    jobOptions: any = null;

    userLoading = false;
    equipmentLoading = false;
    jobLoading = false;

    subscription: Subscription;

    headers: any = {
        responseType: "json",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    beforeUnmount() {
      this.subscription?.unsubscribe();
    }

    searching() {
        this.$emit("filter", this.maintenance);
    }

    reset() {
        this.maintenance = new Maintenance;
        this.$emit("filter", this.maintenance);
    }

    async onUserSearch(query: string) { }

    async onEquipmentSearch(query: string) { }

    async onJobSearch(query: string) { }
}