import { BaseDocumentBloc } from "./bloc.base";
import storage from "@/storage";
import environment from '@/config';
export class CloudDocumentBloc extends BaseDocumentBloc {
    public get base_url() {
        const site = storage.getSync<any>(storage.SITE);
        return `${environment.PROXY_API_URL}/document?hash=${site.hash}`;
    }


    getDocUrl(endpoint) {
        const site = storage.getSync<any>(storage.SITE);
        return `${environment.PROXY_API_URL}/${endpoint}?hash=${site.hash}`;
    }
}

