import { ReportFilter, ReportFormat } from "../../services";
import { ChartComponent } from "./chart";
import { Watch } from "vue-property-decorator";
import { Options } from "vue-class-component";
import { ElLoading } from "element-plus";


@Options({
  props: {
    reportFilter: ReportFilter
  },
})
export default class BoxChartComponent extends ChartComponent {

  @Watch("reportFilter", { immediate: false, deep: true })
  async optionChanged(
    value: ReportFilter,
    oldValue: ReportFilter
  ) {
    if (!value) return;

    try {
      this.fetch(value);
    } catch (e) {
      console.log(e)
    }
  }

  async fetch(reportFilter: ReportFilter, additional: any = {}) {
    const loader = ElLoading.service({ lock: true });

    try {
      const equipmentId = reportFilter.equipment;
      const sensors = await this.reportBloc.getSensorByEquipment(equipmentId);
      this.totalSensors = sensors.length * this.multiplication;
      additional.length = this.totalSensors;
      const datatable: any = await this.reportBloc.getReadings(reportFilter, ReportFormat.Chart, additional);
      const data: any = datatable.data;

      this.labels = [...new Set(data.flatMap((d) => d.created_at))];
      
      this.fetchTable(datatable);
      this.updateDataset(sensors, data);
      this.updateLabel(reportFilter);
      this.updateSensor(data);
    } catch (error) {
      console.log(error);
    }

    loader.close();
  }

}