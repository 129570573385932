import { Options } from "vue-class-component";
import { platform } from "@cems-eco/core/src/utils";
import { BaseAlarmComponent } from "./alarm";
import storage from "@/storage";
import { ElLoading } from "element-plus";
import WebFilterComponent from "../filter/filter.web.vue";
import MobileFilterComponent from "../filter/filter.mobile.vue";

@Options({
  props: {
    equipmentId: String,
    equipment: Object,
    searchMood: Boolean,
  },
  components: platform.factory({
    web: {
      FilterComponent: WebFilterComponent,
    },
    mobile: {
      FilterComponent: MobileFilterComponent,
    },
  }),
})
export class CloudAlarmComponent extends BaseAlarmComponent {

  updateHeaders(): void {
    let auth = storage.getSync<any>(storage.SITE_AUTH);
    if (auth) {
      this.headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
  }

  async searching() {
    const loader = ElLoading.service({ lock: true });

    const searchUrl = await this.monitorBloc.searchParams(this.alarmFilter, this.equipmentId);
    this.dt.ajax.url(searchUrl);
    this.dt.ajax.reload();

    loader.close();
  }
}