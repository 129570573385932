import { BaseManagementApi } from './api.base';
import { apiNet as api } from '@cems-eco/core/src/services/api';

export class CloudManagementApi extends BaseManagementApi {

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async getManagementByClient(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/detail/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async getUrl(url): Promise<any> {
        return await api.getUrl(url);
    }

    async getClientById(id: any): Promise<any> {
        return await api.get<any>(`/client/${id}`, true);
    }

    async getOptions(url): Promise<any[]> {
        let response = await api.get<any>(url, true);
        return (response.data as any[]).map((e) => ({
            label: e.text,
            value: e.id,
        }));
    }

}
