import { ReadingData } from "./index";
import monitorApi from "./api";
import monitorWs from "./ws";
import { ref } from "vue";
import readingApi from "./reading/api";
import { Observable } from "rxjs";
import { Log } from "./log";
import logBloc from "./log/bloc";
import environment from "@/config";
import storage from "@/storage";
import { ISiteStorage, IUserStorage } from "@/storage/model";
import { helper } from "@cems-eco/core/src/utils";


export abstract class BaseMonitorBloc {
    module = 'monitor';
    ws = monitorWs;
    api = monitorApi;
    context = ref([]);
    base_url = environment.API_URL + '/' + module;
    log_url = logBloc.base_url;

    async getReadings(id: any): Promise<ReadingData[]> {
        let response = await readingApi.allByEquipment(id);
        if (response.data) return response.data.map((data) => ({ ...data, id: data.sensor_id }));
        return response.data;
    }

    async getOneMinBy30min(sensor_id: string, timestamp: string): Promise<ReadingData[]> {
        let response = await readingApi.getOneMinBy30min(sensor_id, timestamp);
        if (response.data) return response.data.map((data) => ({ ...data, id: data.sensor_id }));
        return response.data;
    }

    listenReadings30m(userId: string, equipmentId: string): Observable<ReadingData> {
        return this.ws.listen(userId, "cems-30m-" + equipmentId);
    }

    listenReadings1s(userId: string, equipmentId: string): Observable<ReadingData> {
        return this.ws.listen(userId, "cems-1s-" + equipmentId);
    }

    listenAlarm(userId: string, siteId: string): Observable<Log> {
        return this.ws.listen(userId, "cems-alarm-" + siteId);
    }

    async getLogs(id: string): Promise<Log[]> {
        let response = await this.api.logAll(id);
        return response.data.data;
    }

    async getUserStorage(): Promise<IUserStorage> {
        return storage.get<IUserStorage>(storage.LOGIN_TOKEN);
    }

    async getSiteStorage(): Promise<ISiteStorage> {
        return storage.get<ISiteStorage>(storage.SITE);
    }

    // FIXME: not working
    async destroyMonitor(id: any): Promise<void> {
        let response = await readingApi.destroy(id)
        return response.data;
    }

    stopReadings(equipmentId: string, siteId: string): void {
        const channels = [
            ...['cems-1s-', 'cems-30m-'].map((e) => `${e}${equipmentId}`),
            `cems-alarm-${siteId}`
        ];
        Promise.all(channels.map((e) => this.ws.disconnect(e)));
    }

    async getEquipment(id: string): Promise<string> {
        return await this.api.getEquipment(id);
    }

    async getEquipments(): Promise<string> {
        return await this.api.getEquipments();
    }

    async getEquipmentsUrlBySite(): Promise<string> {
        const site = await this.getSiteStorage();
        return await this.api.getUrl('/equipment/site/' + site.id);
    }

    async getLogUrlByEquipment(equipmentId: string): Promise<string> {
        return await this.api.getUrl('/log/equipment/' + equipmentId);
    }

    async getSensorByEquipment(equipment_id: any): Promise<any> {
        let response = await this.api.byEquipment(equipment_id)
        return response.data;
    }

    async searchParams(log: any, equipment_id: any): Promise<string> {
        const uri = helper.toSerializeDatatable(log);
        return this.log_url + '/equipment/' + equipment_id + encodeURI(uri);
    }

}

