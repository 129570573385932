

import { BoxAlarmComponent } from './alarm.box';
import { CloudAlarmComponent } from './alarm.cloud';
import { DemoAlarmComponent } from './alarm.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class System extends platform.mixins(environment.nexus, {
  box: BoxAlarmComponent,
  cloud: CloudAlarmComponent,
  demo: DemoAlarmComponent
}) { }
