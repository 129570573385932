import { Observable } from "rxjs";
import * as NexusStream from "@cems-eco/core/src/services/stream";
import environment from '@/config';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export class BaseMonitorWs {
    ws: any = {};
    receive: any;
    caller: any;
    constructor() { }

    disconnect(channel: string) {
        this.ws[channel].disconnect();
    }

    listen(id: string, channel: string): Observable<any> {
        // TODO: create websocket uid
        const observableSite = from(this.createWs(id, channel));
        const observableWs = new Observable(observer => {
            this.ws[channel].message((data: any) => observer.next(data.message));
        });
        return observableSite.pipe(concatMap(() => observableWs));
    }

    async createWs(id: string, channel: string) {
        const host: string = environment.CEMS_RTC.URL;
        const transports = ['websocket'];
        // const debug = !environment.production;
        const debug = false;
        this.ws[channel] = NexusStream.channel(channel, { host, transports, debug });
        this.ws[channel].catch(this.error);
    }

    error(error: any) {
        console.error(error);
    }
}
