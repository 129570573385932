import { Vue } from "vue-class-component";
import { ref } from "vue";
import { Options } from 'vue-class-component';
import { Watch } from "vue-property-decorator";


@Options({
    props: ['modelValue', 'viewOnly'],
    emits: ['update:modelValue']
})
export default class WebMaintenancePartComponent extends Vue {
    value = ref([]);

    mounted(): void {
        setTimeout(() => {
            this.value = this.$props['modelValue']
        }, 500);
    }

    onCreate() {
        return {
            part_number: '',
            quantity: 1,
            description: '',
            remarks: '',
        }
    }

    @Watch("value", { immediate: false, deep: true })
    onUpdate() {
        this.$emit('update:modelValue', this.value)
    }
}