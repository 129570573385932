import { BaseCemsReadingComponent } from "./reading";
import { ChartConfiguration, ChartOnclick } from "@cems-eco/core/src/models";
import { ReadingData } from "../../services";
import formatter from "@cems-eco/core/src/services/formatter/formatter";
import { helper } from "@cems-eco/core/src/utils";
import { Options } from "vue-class-component";
import { ElLoading } from "element-plus";
import moment from 'moment';
import { DisplaySensor, ReadingComponent } from "./interfaces";

@Options({
  props: {
    equipment: Object,
    type: String
  },
})
export class BoxReadingComponent extends BaseCemsReadingComponent implements ReadingComponent {
  sensors: DisplaySensor[] = [];
  async created() {
    this.setChartConfig();
    this.setChartOneMinConfig();
    this.getAlarm();
  }

  async mounted() {
    const loader = ElLoading.service({ lock: true });

    this.fetch();

    const userStorage = await this.monitorBloc.getUserStorage();
    this.monitorBloc.listenReadings30m(userStorage.id, this.equipment.id).subscribe(this.listenReadings30m);
    this.monitorBloc.listenReadings1s(userStorage.id, this.equipment.id).subscribe(this.listenReadings1s);
    loader.close();
  }

  async unmounted() {
    const siteStorage = await this.monitorBloc.getSiteStorage();
    this.monitorBloc.stopReadings(this.equipment.id, siteStorage.id);
  }

  getRandomNumber(total) {

  }

  async getAlarm() {
    try {
      const userStorage = await this.monitorBloc.getUserStorage();
      const siteStorage = await this.monitorBloc.getSiteStorage();

      this.monitorBloc.listenAlarm(userStorage.id, siteStorage.id).subscribe(async (alarm: any) => {
        this.sensors = this.sensors.map((sensor) => {
          try {
            if (sensor.id == alarm.sensor_id) return { ...sensor, alarm: alarm.alarm_popup == 'yes', message: alarm.message };
          } catch (e) { }
          return sensor;
        })
      });

    } catch (error) {
      console.log(error);
    }
  }


  listenReadings1s(data: ReadingData) {
    this.sensors = this.sensors.map((sensor) => {
      if (sensor.id == data.id) return { ...sensor, value: Number(data.value).toFixed(2) };
      return sensor;
    })

  }


  listenReadings30m(data: any) {
    try {
      const label = formatter.shortTime(data.created_at);
      const value = Number(Number(data.value).toFixed(2));
      let shift = false;

      if (!this.labels.includes(label)) {
        this.labels.push(label);
        if (this.labels.length >= this.xValueMax) {
          this.labels.shift();
          shift = true;
        }
      }


      this.labels = this.labels.map((l) => formatter.shortTime(l).includes("Invalid") ? l : formatter.shortTime(l));

      this.datasets = this.datasets.map((dataset: any) => {
        if (shift) dataset.data.shift();

        if (dataset.id == data.id) {
          dataset.data.push(value);
          dataset.labels.push(data.created_at);
        }

        return dataset;
      });

      return this.updateConfig();

    } catch (e) {
      console.log(e);
    }
  }


  updateConfig() {
    this.config.data = {
      datasets: JSON.parse(JSON.stringify(this.datasets)),
      labels: JSON.parse(JSON.stringify(this.labels)),
    };
  }


  async fetch() {
    const sensors = await this.monitorBloc.getSensorByEquipment(this.equipment.id);
    const data: ReadingData[] = await this.monitorBloc.getReadings(this.equipment.id);

    this.labels = [...new Set(data.flatMap((d) => d.created_at))].reverse();
    const colors: any = {};
    this.datasets = sensors.map((sensor) => {
      colors[sensor.id] = helper.getRandomColor(4);
      return {
        id: sensor.id,
        label: sensor.name,
        borderColor: colors[sensor.id],
        fill: false,
        labels: JSON.parse(JSON.stringify(this.labels)),
        data: this.labels.length < 1 ? [] : this.labels
          .map((created_at) => {
            const r = data.find((d) => d.created_at == created_at && d.id == sensor.id)
            return (r != undefined) ? r.value : 0;
          })
          .filter((d) => d != null),

      }
    });

    this.config.data = {
      labels: this.labels.map((l) => formatter.shortTime(l)),
      datasets: this.datasets,
    }

    this.sensors = sensors.map((sensor) => ({
      id: sensor.id,
      name: sensor.name,
      unit: sensor.unit,
      value: "-",
      alarm: false,
      message: '',
      color: colors[sensor.id]
    }));
  }

  setChartConfig() {
    this.config = {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },

      // Configuration options go here
      options: {
        responsive: true,
        aspectRatio: 2.5,
        maintainAspectRatio: this.type == 'basic',
        layout: {
          padding: {
            top: 7,
            right: 7,
          }
        },
        legend: {
          display: this.type == 'advance',
          labels: {
            fontColor: this.type == 'basic' ? '#fff' : '#000'
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
              padding: 12
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'advance'
            },
          }],
          xAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'basic',
            },
          }]
        }
      },
    };
  }

  setChartOneMinConfig() {
    this.oneMinConfig = {
      type: 'line',
      data: {
        labels: this.oneMinLabels,
        datasets: this.oneMinDatasets,
      },

      // Configuration options go here
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
              padding: 12
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'advance'
            },
          }],
          xAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'basic',
            },
          }]
        }
      },
    };
  }

  reading() { }

  async min30Onclick(res: ChartOnclick) {
    if (this.type == "basic") return;

    const loader = ElLoading.service({ lock: true });
    const sensor_id = res.dataset.id;

    const timestamp = moment(res.dataset.labels[res.index]).format('YYYY-MM-DD HH:mm:ss');
    const data = await this.monitorBloc.getOneMinBy30min(sensor_id, timestamp);
    const dataName = [...new Set(data.flatMap((d) => d.name))].sort((a: any, b: any) => a.localeCompare(b));

    this.oneMinLabels = [...new Set(data.flatMap((d) => d.created_at))].reverse();

    this.oneMinDatasets = dataName.map((name: any) => ({
      id: data.find((d) => d.name.toLowerCase() == name.toLowerCase())!.id,
      label: name,
      borderColor: res.dataset.borderColor,
      fill: false,
      data: this.oneMinLabels
        .map((created_at) => {
          const r = data.find((d) => d.created_at == created_at && d.name.toLowerCase() == name.toLowerCase())
          return (r != undefined) ? r.value : 0;
        })
        .filter((d) => d != null),
    }));

    this.oneMinConfig.data = {
      labels: this.oneMinLabels.map((l) => formatter.shortTime(l)),
      datasets: this.oneMinDatasets,
    }
    this.isShowOneMin = true;
    loader.close();
  }

  backTo30min() {
    this.isShowOneMin = false;
  }
}

