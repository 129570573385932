import reportBloc from "../services/bloc";
import { Tab } from "@cems-eco/core/src/models";
import { Vue } from "vue-class-component";


export abstract class ReportPage extends Vue {
    reportBloc = reportBloc;
    tabs: Tab
    activeTabIndex: number = 0;
    hasData = false;
    reportFilter;

    async created() {
        this.setTabs();
    }

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'table',
                name: 'Table',
                icon: 'table',
                active: true,
                component: 'TableComponent'
            },
            {
                id: 'chart',
                name: 'Chart',
                icon: 'chart-line',
                active: false,
                component: 'ChartComponent'
            },
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }

    getFilter(filter: any) {
        this.reportFilter = filter;
        this.hasData = !this.reportFilter.isEmpty();
    }

}

