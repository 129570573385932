import { BaseDocumentApi } from './api.base';
import { apiNet as api } from '@cems-eco/core/src/services/api';

export class CloudDocumentApi extends BaseDocumentApi {
    
    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async upload(data: any): Promise<any> {
        data = api.toFormData(data);
        return await api.post<any>(`${this.BASE_URL}/upload/bulk`, data, true, true);
    }

}
