import { AlarmFilter } from "../../services";
import monitorBloc from "../../services/bloc";
import { Vue } from 'vue-class-component';
import moment from "moment";


export abstract class AlarmFilterComponent extends Vue {
    monitorBloc = monitorBloc;
    alarmFilter = new AlarmFilter;
    levels = [
        {
            label: 'Normal',
            value: 0,
        },
        {
            label: 'Warning',
            value: 1,
        },
        {
            label: 'Critical',
            value: 2,
        },
    ];
    levelOptions: any[] = [];
    levelLoading = false;
    startDatetime = null;
    endDatetime = null;

    searching() {
        this.$emit("filter", this.alarmFilter);
    }

    reset() {
        this.alarmFilter = new AlarmFilter;
        this.startDatetime = null;
        this.endDatetime = null;
        this.$emit("filter", this.alarmFilter);
    }

    startDateDisabled(ts: number) {
        const date = new Date(ts);
        return moment().isBefore(date, 'day');
    }

    endDateDisabled(ts: number) {
        const date = new Date(ts);
        if (moment().isBefore(date, 'day')) return true;
        return moment(this.alarmFilter.start_date).isAfter(date, 'day');
    }

    onStartChanged() {
        this.alarmFilter.start_date = moment(this.startDatetime ?? '').format('YYYY-MM-DD');

        const initial = new AlarmFilter;
        this.alarmFilter.end_date = initial.end_date;
        this.endDatetime = null;
    }

    onEndChanged() {
        this.alarmFilter.end_date = moment(this.endDatetime ?? '').format('YYYY-MM-DD');
    }

    async onEquipmentSearch(query: string) { }

    async onTypeSearch(query: string) { }
}