import { ReportFilter, ReportFormat } from "../../services";
import { ElLoading } from "element-plus";
import { ReportTableComponent } from "./table";
import { Options } from "vue-class-component";
import { widget } from "@cems-eco/core/src/utils";


@Options({
  props: {
    reportFilter: ReportFilter
  },
})
export default class DemoTableComponent extends ReportTableComponent {

  async builDataTable() {
    const loader = ElLoading.service({ lock: true });
    this.options = {
      mobileView: {
        header: {
          title: function (row: any) {
            return row[1];
          },
        }
      },
      columns: [
        { mobileView: true },
        { mobileView: false },
        { mobileView: true },
        { mobileView: true },
      ],
      data: [
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "DUST",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "NOx",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "SO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
        [
          "CO",
          "GAS ANALYZER (6500160)",
          "18.4",
          "2023-08-31T16:00:20.000000Z"
        ],
      ],
      buttons: [
        // "csv",
        // "pdf"
        {
          extend: 'collection',
          text: '<span class="fas fa-file-download pr-2" tooltip data-title="Download"></span>',
          buttons: [
            "csv",
            "pdf",
          ],
        },
      ],
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
    };

    loader.close();
  }

  async downloadFile(format: ReportFormat) {
    const loader = ElLoading.service({ lock: true, text:  'Generating...\nIt may take time'});
    try {
      const now = new Date();
      // const date = formatter.custom(now, 'Y.M.D');
      // await this.reportBloc.downloadFile(this.reportFilter, format, `report-${date}.${format}`);
      loader.close();
    } catch (error) {
      loader.close();
      widget.alertError(error);
    }
  }
}