import { BaseMaintenanceApi } from './api.base';
import { apiNet } from '@cems-eco/core/src/services/api';

export class CloudMaintenanceApi extends BaseMaintenanceApi {

    async all(): Promise<any> {
        return await apiNet.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await apiNet.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await apiNet.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await apiNet.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async download(fileName: string, id: any): Promise<any> {
        return await apiNet.downLoadFile<any>(`${this.BASE_URL}/pdf/${id}`, fileName, true);
    }

    async destroy(id: any): Promise<any> {
        return await apiNet.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async storeRelation(data: any): Promise<any> {
        return await apiNet.post<any>(`${this.BASE_URL}/relation/store`, data, true);
    }

    async updateRelation(id: any, data: any): Promise<any> {
        return await apiNet.post<any>(`${this.BASE_URL}/relation/${id}`, data, true);
    }

    async deleteRelation(id: any): Promise<any> {
        return await apiNet.delete<any>(`${this.BASE_URL}/relation/${id}`, true);
    }

    async serialNumber(): Promise<any> {
        return await apiNet.get<any>(`${this.BASE_URL}/serial-number`, true);
    }

    async clientFirst(id: any): Promise<any> {
        return await apiNet.get<any>(`/client/${id}`, true);
    }

    async siteFirst(id: any): Promise<any> {
        return await apiNet.get<any>(`/site/${id}`, true);
    }


    async equipmentSelect2BySite(id: any): Promise<any> {
        return await apiNet.get<any>(`/equipment/select2/site/${id}`, true);
    }

    async equipmentFirst(id: any): Promise<any> {
        return await apiNet.get<any>(`/equipment/${id}`, true);
    }

    async userFirst(id: any): Promise<any> {
        return await apiNet.get<any>(`/user/${id}`, true);
    }

    async getUrl(url): Promise<string> {
        return await apiNet.getUrl(url);
    }

    async getOptions(url): Promise<any[]> {
        let response = await apiNet.get<any>(url, true);
        return (response.data as any[]).map((e) => ({
            label: e.text,
            value: e.id,
        }));
    }
}
