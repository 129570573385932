import { Vue } from "vue-class-component";
import managementBloc from "../../services/bloc";
import moment from "moment";


export class ManagementDetailComponent extends Vue {
    managementBloc = managementBloc;

    modelValue: any = {};
    viewOnly = false;
    isLoading = true;

    clientOptions: any = null;

    contractExpiryDisabled(ts: number) {
        const date = new Date(ts);
        return moment(this.modelValue.installation_date).isSame(date, 'day') || moment(this.modelValue.installation_date).isAfter(date, 'day');
    }

}