import { CloudLogApi } from "./api.cloud";
import { BoxLogApi } from "./api.box";
import { platform } from "@cems-eco/core/src/utils";
import { BaseLogApi } from "./api.base";
import environment from "@/config";

export default platform.service<BaseLogApi>(environment.nexus, {
    box: new BoxLogApi(),
    cloud: new CloudLogApi(),
    demo: new CloudLogApi(),
});