import { platform } from "@cems-eco/core/src/utils";
import DetailsComponent from "./details/details.vue";
import WebParts from "./parts/parts.web.vue";
import MobileParts from "./parts/parts.mobile.vue";
import WebFilter from "./filter/filter.web.vue";
import MobileFilter from "./filter/filter.mobile.vue";


export default platform.factory({
    web: {
        DetailsComponent,
        PartsComponent: WebParts,
        FilterComponent: WebFilter,
    },
    mobile: {
        DetailsComponent,
        PartsComponent: MobileParts,
        FilterComponent: MobileFilter,
    },
}); 
