import { platform } from "@cems-eco/core/src/utils";
import { BaseMonitorWs } from "./ws.base";
import environment from "@/config";
import { CloudMonitorWs } from "./ws.cloud";
import { BoxMonitorWs } from "./ws.box";

export default platform.service<BaseMonitorWs>(environment.nexus, {
    box: new BoxMonitorWs(),
    cloud: new CloudMonitorWs(),
});
