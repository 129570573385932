import { BaseCemsReadingComponent } from "./reading";
import { Options } from "vue-class-component";
import { ChartConfiguration, ChartOnclick } from "@cems-eco/core/src/models";
import { ReadingComponent } from "./interfaces";
import { ElLoading } from "element-plus";
import { helper } from "@cems-eco/core/src/utils";
import { ReadingData } from "../../services";

@Options({
  props: {
    equipment: Object,
    type: String
  },
})
export class DemoReadingComponent extends BaseCemsReadingComponent implements ReadingComponent {


  sensors: any[] = [];
  async created() {
    this.setChartConfig();
    this.setChartOneMinConfig();
  }

  async mounted() {
    const loader = ElLoading.service({ lock: true });

    this.fetch();
    const scope = this;
    setInterval(() => {
      scope.reading();
    }, 1000);
    loader.close();
  }

  async getAlarm() { }
  listenReadings1s(data: ReadingData) { }
  listenReadings30m(data: any) { }
  updateConfig() { }

  getRandomNumber(total) {

    const randomNumbers: any = [];

    for (var i = 0; i < total; i++) {
      const randomNumber: any = Math.floor(Math.random() * (100));
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  }


  setChartConfig() {
    // Dust, CO, NOx, SO
    this.datasets = [
      {
        id: "1",
        label: "DUST",
        borderColor: helper.getRandomColor(4),
        fill: false,
        data: this.getRandomNumber(10),
      },
      {
        id: "2",
        label: "CO",
        borderColor: helper.getRandomColor(4),
        fill: false,
        data: this.getRandomNumber(10),
      },
      {
        id: "3",
        label: "NOx",
        borderColor: helper.getRandomColor(4),
        fill: false,
        data: this.getRandomNumber(10),
      },
    ];
    this.config = {
      type: 'line',
      data: {
        labels: [
          "7:00:00 pm",
          "7:30:00 pm",
          "8:00:00 pm",
          "8:30:00 pm",
          "9:00:00 pm",
          "9:30:00 pm",
          "10:00:00 pm",
          "10:30:00 pm",
          "11:00:00 pm",
          "11:30:00 pm",
        ],
        datasets: this.datasets,
      },

      // Configuration options go here
      options: {
        responsive: true,
        aspectRatio: 2.5,
        maintainAspectRatio: this.type == 'basic',
        layout: {
          padding: {
            top: 7,
            right: 7,
          }
        },
        legend: {
          // display: this.type == 'advance',
          position: 'bottom',
          labels: {
            // fontColor: this.type == 'basic' ? '#fff' : '#000'
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
              padding: 12
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'advance'
            },
          }],
          xAxes: [{
            ticks: {
              display: this.type == 'advance',
              fontColor: "#BEBEBE",
              fontSize: 13,
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
              display: this.type == 'basic',
            },
          }]
        }
      },
    };
  }

  setChartOneMinConfig() {
    this.oneMinDatasets = [{
      id: "1",
      label: "DUST",
      borderColor: helper.getRandomColor(4),
      fill: false,
      data: [90, 80, 70, 80, 90, 80, 70, 80, 90, 80,],
    }];
    this.oneMinConfig = {
      type: 'line',
      data: {
        labels: [
          "7:00:00 pm",
          "7:30:00 pm",
          "8:00:00 pm",
          "8:30:00 pm",
          "9:00:00 pm",
          "9:30:00 pm",
          "10:00:00 pm",
          "10:30:00 pm",
          "11:00:00 pm",
          "11:30:00 pm",
        ],
        datasets: this.oneMinDatasets,
      },

      // Configuration options go here
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              fontColor: "#BEBEBE",
              fontSize: 13,
              padding: 12
            },
            gridLines: {
              borderDash: [8, 4],
              zeroLineWidth: 0,
              drawBorder: false,
            },
          }],
          xAxes: [{
            ticks: {
              fontColor: "#BEBEBE",
              fontSize: 13,
            },
            gridLines: {
              display: false,
            },
          }]
        }
      },
    };
  }

  async fetch() {
    this.sensors = [
      {
        id: "id",
        name: "Dust",
        unit: "mg/m3",
        value: "-",
        alarm: false,
        message: ''
      },
      {
        id: "id",
        name: "CO",
        unit: "mg/m3",
        value: "-",
        alarm: false,
        message: ''
      },
      {
        id: "id",
        name: "NOx",
        unit: "mg/m3",
        value: "-",
        alarm: false,
        message: ''
      },
    ];
  }

  reading() {
    this.sensors = this.sensors.map((sensor) => {
      return { ...sensor, value: Number(Math.floor(Math.random() * 10) + 1).toFixed(2), alarm: !sensor.alarm };
    })
  }

  async min30Onclick(res: ChartOnclick) {
    const { id, label, borderColor, fill, data } = res.dataset;

    this.oneMinDatasets = [
      { id, label, borderColor, fill, data },
    ];
    this.oneMinConfig.data.datasets = this.oneMinDatasets;
    this.isShowOneMin = true;
  }

  backTo30min() {
    this.isShowOneMin = false;
  }
}

