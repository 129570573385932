
import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

import { BoxMaintenanceDetailComponent } from "./details.box";
import { CloudMaintenanceDetailComponent } from "./details.cloud";
import { DemoMaintenanceDetailComponent } from "./details.demo";

export default class Details extends platform.mixins(environment.nexus, {
    box: BoxMaintenanceDetailComponent,
    cloud: CloudMaintenanceDetailComponent,
    demo: DemoMaintenanceDetailComponent,
}) { }
