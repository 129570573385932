
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import BoxFilterComponent from "./filter.box";
import CloudFilterComponent from "./filter.cloud";
import DemoFilterComponent from "./filter.demo";

export default class Mobile extends platform.mixins(environment.nexus, {
  box: BoxFilterComponent,
  cloud: CloudFilterComponent,
  demo: DemoFilterComponent,
}) {
  drawer: any = false;

  openAdvanceSearch() {
    this.drawer = !this.drawer;
  }

  searchingMobile() {
    this.drawer = false;
    this.searching();
  }

  resetMobile() {
    this.drawer = false;
    this.reset();
  }
}
