import { ElLoading } from "element-plus";
import { ManagementTableComponent } from "./table";
import moment from "moment";
import { Options } from "vue-class-component";


@Options({
  props: ['modelValue', 'viewOnly'],
})
export default class DemoTableComponent extends ManagementTableComponent {

  updateHeaders(): void {}

  async builDataTable() {
    const loader = ElLoading.service({ lock: true });
    this.options = {
      mobileView: {
        header: {
          actions: [
              {
                  id: 'downloadAction',
                  template: '<i class="fas fa-file-download text-warning" title="Download" ></i> Download',
              },
              {
                  id: 'deleteAction',
                  template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
              },
          ],
          title: function (row: any) {
            return row[1];
          },
        }
      },
      columns: [
        {
          mobileView: true,
        },
        {
          mobileView: false,
        },
        {
          mobileView: true,
        },
        {
          mobileView: true,
          render: function (data: any) {
            return moment(data).format('DD/MM/YYYY h:mm:ss');
          },
        },
        {
          mobileView: false,
          title: "Action",
          render: function (data: any, type: any, full: any, meta: any) {
            return (
              '<div class="form-group" style="margin: auto;">' +
              '<div class="btn-group" role="group">' +
              '<a class="btn btn-primary btn-sm download text-white" id="downloadAction" download target="_blank" href="' + data + '"><i class="fas fa-download" data-placement="top" title="Download"></i></a>' +
              `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
              "</div>" +
              "</div>"
            );
          },
        },
      ],
      data: [
        [
          "Shahrin Nidzam",
          "Filename.pdf",
          "Sample pdf file",
          "2023-08-31T16:00:20.000000Z",
          "https://www.africau.edu/images/default/sample.pdf"
        ],
      ],
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
    };

    loader.close();
  }
}