import { ElLoading } from "element-plus";
import { MaintenanceDetailComponent } from "./details";


export class DemoMaintenanceDetailComponent extends MaintenanceDetailComponent {
    private clients = [
        {
            label: "ADA.IR SDN BHD",
            value: "client",
        },
    ];
    private sites = [
        {
            label: "Sri Kembangan",
            value: "site",
        },
    ];
    private equipments = [
        {
            label: "GAS ANALYZER (6500160)",
            value: "equipment",
        },
    ];
    private users = [
        {
            label: "Shahrin Nidzam",
            value: "user",
        },
    ];
    private jobs = [
        {
            label: "Preventive Maintenance",
            value: "Preventive Maintenance",
        },
        {
            label: "Replacement",
            value: "Replacement",
        },
        {
            label: "Installation",
            value: "Installation",
        },
        {
            label: "Site Survey",
            value: "Site Survey",
        },
        {
            label: "Project",
            value: "Project",
        },
        {
            label: "Commissioning",
            value: "Commissioning",
        },
        {
            label: "Breakdown Service",
            value: "Breakdown Service",
        },
        {
            label: "Training",
            value: "Training",
        },
        {
            label: "Sales Support",
            value: "Sales Support",
        },
    ];

    mounted(): void {
        const loader = ElLoading.service({ lock: true });

        this.clientOptions = this.clients;
        this.siteOptions = this.sites;
        this.equipmentOptions = this.equipments;
        this.userOptions = this.users;
        this.jobOptions = this.jobs;
        
        setTimeout(() => {
            this.updateDatetimeInput();
        }, 500);

        loader.close();
        this.isLoading = false;
    }

    async onEquipmentSearch(query: string) {
        this.equipmentLoading = true
        window.setTimeout(() => {
            this.equipmentOptions = this.equipments.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            )
            this.equipmentLoading = false
        }, 1000)
    }

    async onJobSearch(query: string) {
        this.jobLoading = true
        window.setTimeout(() => {
            this.jobOptions = this.jobs.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            )
            this.jobLoading = false
        }, 1000)
    }

    async onWorkProgressSearch(query: string) {
        this.workProgressLoading = true
        window.setTimeout(() => {
            this.workProgressOptions = this.workProgresses.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            )
            this.workProgressLoading = false
        }, 1000)
    }
}