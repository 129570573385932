import { EquipmentData } from "../../services";
import monitorBloc from "../../services/bloc";
import formatter from "@cems-eco/core/src/services/formatter/formatter";
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    equipmentId: String,
    equipment: Object,
    searchMood: Boolean,
  },
})
export class BaseSystemComponent extends Vue {

  monitorBloc = monitorBloc
  equipments: EquipmentData[];
  equipment: EquipmentData;
  equipmentId: any;

  formatDate(value: any) {
    if (value) return formatter.short(value)
  }

}