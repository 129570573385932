import { isEmpty } from "lodash";

export interface IMonitor {
    readonly id: any;
    name: string
    description: string
}

export class Monitor {
    public id!: any;
    public _method!: string;
    public name!: string;
    public additional!: string;

}

export interface ReadingData {
    id: string;
    name: string;
    value: number;
    unit: string;
    created_at: string;
}

export interface EquipmentData {
    name: string;
    manufacturer: string;
    model: string;
    date_manufactured: Date;
    serial_number: string;
    measurements: string[];
    configurations: string[];
}


export class AlarmFilter {
    public start_date!: string;
    public end_date!: string;
    public level!: number;

    public get isEmpty(): any {
        return !this.start_date || !(this.end_date && this.end_date != "Invalid date") || !this.level;
    }
}