import { AlarmFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { Options } from 'vue-class-component';


@Options({
  emits: ['filter']
})
export default class DemoFilterComponent extends AlarmFilterComponent {

  async mounted() {
    const loader = ElLoading.service({ lock: true });
    await this.buildLevelSelect();
    loader.close();
  }

  async buildLevelSelect() {
    this.levelOptions = this.levels;
  }

  async onTypeSearch(query: string) {
    if (!query.length) {
      this.levels = []
      return
    }
    this.levelLoading = true
    window.setTimeout(() => {
      this.levels = this.levels.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.levelLoading = false
    }, 1000)
  }
}