
import { BoxReadingComponent } from './reading.box';
import { CloudReadingComponent } from './reading.cloud';
import { DemoReadingComponent } from './reading.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class WebReading extends platform.mixins(environment.nexus, {
  box: BoxReadingComponent,
  cloud: CloudReadingComponent,
  demo: DemoReadingComponent
}){}
