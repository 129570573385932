import { Report, ReportFilter, ReportFormat } from "./index"
import reportApi from "./api"
import environment from "@/config"
import { helper } from "@cems-eco/core/src/utils"

export abstract class BaseReportBloc {
    module = 'report';
    api = reportApi;
    base_url = environment.API_URL + '/' + module;

    async getReadings(reportFilter: ReportFilter, format: ReportFormat, additionalParam: any = {}): Promise<Report[]> {
        let response = await this.api.allByReportFilter(reportFilter, format, additionalParam);
        return response.data;
    }

    async getReports(): Promise<[Report]> {
        let response = await this.api.all()
        return response.data.data;
    }

    async getReport(id: any): Promise<Report> {
        let response = await this.api.first(id)
        return response.data;
    }

    async createReport(report: Report): Promise<Report> {
        let response = await this.api.create(report)
        return response.data;
    }

    async updateReport(report: Report): Promise<Report> {
        let response = await this.api.edit(report.id, report)
        return response.data;
    }

    // FIXME: not working
    async destroyReport(id: any): Promise<void> {
        let response = await this.api.destroy(id)
        return response.data;
    }

    async searchParams(reportFilter: ReportFilter, format: ReportFormat): Promise<string> {
        const uri = helper.toSerializeDatatable(reportFilter);
        return await this.api.getUrl('/report/' + format.toString() + encodeURI(uri));
    }

    async downloadFile(reportFilter: ReportFilter, format: ReportFormat, fileName: string): Promise<void> {
        return this.api.download(reportFilter, format, fileName);
    }

    async getSensorByEquipment(equipment_id: any): Promise<any> {
        let response = await this.api.sensorByEquipment(equipment_id)
        return response.data;
    }

    async getEquipmentOptions(): Promise<any[]> {
        return await this.api.getOptions('/equipment/select2');
    }

    async getReadingTypeOptions(): Promise<any[]> {
        return await this.api.getOptions('/reference/select2/reading-type', 'text');
    }

    async getHeaderReport(reportFilter: ReportFilter): Promise<any> {
        let response = await this.api.header(reportFilter);
        return response.data;
    }

}

