import { ManagementTableComponent } from "./table";
import storage from "@/storage";
import { Options } from "vue-class-component";
import moment from "moment";
import environment from '@/config';
import { api } from '@cems-eco/core/src/services/api';


@Options({
  props: ['modelValue', 'viewOnly'],
})
export default class BoxTableComponent extends ManagementTableComponent {

  updateHeaders(): void {
    let auth = storage.getSync<any>(storage.AUTH);
    if (auth) {
      this.headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
  }
}