import { ChartComponent } from "./chart";
import { widget } from "@cems-eco/core/src/utils";
import { helper } from "@cems-eco/core/src/utils";
import { Options } from "vue-class-component";
import { ReportFilter } from "../../services";
import { ElLoading } from "element-plus";


@Options({
  props: {
    reportFilter: ReportFilter
  },
})
export default class DemoChartComponent extends ChartComponent {

  getRandomNumber(total) {
    const randomNumbers: any = [];

    for (var i = 0; i < total; i++) {
      const randomNumber: any = Math.floor(Math.random() * (100 - 30 + 1)) + 30;
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  }

  async fetch($data) {
    const loader = ElLoading.service({ lock: true });

    try {
      this.datasets = ["DUST", "CO", "NOx", "SO"].map((label, index) => {
        const color = helper.getRandomColor(4);
        return {
          id: `${index + 1}`,
          label,
          borderColor: color,
          backgroundColor: helper.convertRgbToRgba(color, 0.3),
          fill: true,
          data: this.getRandomNumber(10),
        };
      });
      this.labels = [
        "1 Sep, 2023, 7:00:00 pm",
        "1 Sep, 2023, 7:30:00 pm",
        "1 Sep, 2023, 8:00:00 pm",
        "1 Sep, 2023, 8:30:00 pm",
        "1 Sep, 2023, 9:00:00 pm",
        "1 Sep, 2023, 9:30:00 pm",
        "1 Sep, 2023, 10:00:00 pm",
        "1 Sep, 2023, 10:30:00 pm",
        "1 Sep, 2023, 11:00:00 pm",
        "1 Sep, 2023, 11:30:00 pm",
      ];
      this.tableData = {
        from: 1,
        to: 10,
        total: 10
      };

      this.links = [
        {
          label: "pagination.previous"
        },
        {
          label: "1",
          active: "active"
        },
        {
          label: "pagination.next"
        }
      ];
      this.setChartConfig();
    } catch (error) {
      console.log(error);
    }

    loader.close();
  }

}