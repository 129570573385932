import { BaseDocumentBloc } from "./bloc.base";
import environment from "@/config"

export class BoxDocumentBloc extends BaseDocumentBloc {
    base_url = environment.API_URL + '/document';

    getDocUrl(endpoint) {
        return `${environment.BASE_URL}/${endpoint}`;
    }
}

