import { ChartConfiguration, ChartOnclick } from "@cems-eco/core/src/models";
import monitorBloc from "../../services/bloc";
import { Dataset, DisplaySensor, ReadingComponent } from "./interfaces";
import { Vue } from "vue-class-component";
import { ReadingData } from "../../services";
import router from "@/router";


export abstract class BaseCemsReadingComponent extends Vue implements ReadingComponent {

  monitorBloc = monitorBloc;
  config: ChartConfiguration = new ChartConfiguration;
  datasets: Dataset[] = [];
  labels: string[] = [];
  oneMinConfig: ChartConfiguration = new ChartConfiguration;
  oneMinDatasets: Dataset[] = [];
  oneMinLabels: any[] = [];
  active = false;
  xValueMax = 10;
  isShowOneMin = false;
  equipment: any;
  site: any;
  type: String;

  public get option(): ChartConfiguration {
    return this.config;
  }

  public get oneMinOption(): ChartConfiguration {
    return this.oneMinConfig;
  }

  abstract getAlarm();
  abstract listenReadings1s(data: ReadingData);
  abstract listenReadings30m(data: any);
  abstract updateConfig();

  min30Onclick(res: ChartOnclick) {
    throw new Error("Method not implemented.");
  }

  backTo30min() {
    throw new Error("Method not implemented.");
  }

  openPage() {
    // console.log('openPage');
    
    if (this.type == 'advance') return;
    router.push('/monitor/' + this.equipment.id);
  }
}

