import { Vue, prop } from "vue-class-component";


export class Props {
    options = prop({
        type: Object,
    })
}
export default class CemsInfoComponent extends Vue.with(Props) {

}