import * as NexusStream from "@cems-eco/core/src/services/stream";
import environment from '@/config';
import storage from "@/storage";
import { BaseMonitorWs } from "./ws.base";

export class CloudMonitorWs extends BaseMonitorWs {

    async createWs(id: string, channel: string) {
        const host: string = environment.CEMS_RTC.URL;
        const site = await storage.get<any>(storage.SITE);
        const queryParameters = `hash=${site.hash}`;
        const transports = ['websocket'];
        // const debug = !environment.production;
        const debug = false;
        this.ws[channel] = NexusStream.channel(channel, { host, queryParameters, transports, debug });
        this.ws[channel].catch(this.error);
    }
}
