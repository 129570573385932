import { ManagementTableComponent } from "./table";
import { Options } from "vue-class-component";
import storage from "@/storage";


@Options({
  props: ['modelValue', 'viewOnly'],
})
export default class CloudTableComponent extends ManagementTableComponent {

  updateHeaders(): void {
    let auth = storage.getSync<any>(storage.SITE_AUTH);
    if (auth) {
      this.headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
  }
}