import { ReportPage } from ".";
import { Options } from "vue-class-component";
import component from "../components/component";


@Options({
    components: component,
})
export default class CloudReportPage extends ReportPage {
}

