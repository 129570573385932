
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import BoxTableComponent from "./table.box";
import CloudTableComponent from "./table.cloud";
import DemoTableComponent from "./table.demo";

export default class Mobile extends platform.mixins(environment.nexus, {
  box: BoxTableComponent,
  cloud: CloudTableComponent,
  demo: DemoTableComponent,
}) { }
