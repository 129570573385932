
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import BoxFilterComponent from "./filter.box";
import CloudFilterComponent from "./filter.cloud";
import DemoFilterComponent from "./filter.demo";

export default class Web extends platform.mixins(environment.nexus, {
  box: BoxFilterComponent,
  cloud: CloudFilterComponent,
  demo: DemoFilterComponent,
}) { }
