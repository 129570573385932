
export interface IReport {
    readonly id: any;
    name: string
    description: string
}

export class Report {
    public id!: any;
    public _method!: string;
    public name!: string;
    public unit!: string;
    public equipment_id!: string;
    public value!: string;
    public created_at!: string;
}

export class ReportFilter {
    public equipment!: string;
    public start_date!: string;
    public end_date!: string;
    public start_time!: string;
    public end_time!: string;
    public type!: string;

    public isEmpty(): boolean {
        return !this.type || !this.equipment || !this.start_date || !this.end_date || !this.start_time || !this.end_time;
    }
}

export enum ReportFormat {
    Table = "table",
    Chart = "chart",
    CSV = "csv",
    PDF = "pdf"
}