import { Options } from "vue-class-component";
import { platform } from "@cems-eco/core/src/utils";
import { BaseAlarmComponent } from "./alarm";
import { ElLoading } from "element-plus";
import { range } from "lodash";
import WebFilterComponent from "../filter/filter.web.vue";
import MobileFilterComponent from "../filter/filter.mobile.vue";

@Options({
  props: {
    equipmentId: String,
    equipment: Object,
    searchMood: Boolean,
  },
  components: platform.factory({
    web: {
      FilterComponent: WebFilterComponent,
    },
    mobile: {
      FilterComponent: MobileFilterComponent,
    },
  }),
})
export class DemoAlarmComponent extends BaseAlarmComponent {

  async builDataTable() {
    const loader = ElLoading.service({ lock: true });
    const scope: any = this;
    this.options = {
      mobileView: {
        header: {
          title: function (row: any) {
            return row[1];
          },
        }
      },
      pageLength: 5,
      lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
      columns: [
        { mobileView: true },
        { mobileView: false },
      ],
      data: [
        [
          "-1% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-2% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-3% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-4% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-5% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-6% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-7% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-8% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-9% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-10% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-11% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-12% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-13% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-14% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
        [
          "-15% - Invalid Value",
          "2021-04-21 18:02:19.000"
        ],
      ],
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
    };
    loader.close();
  }

  async searching() {
    const loader = ElLoading.service({ lock: true });

    if (!this.alarmFilter.isEmpty) {
      range(10).map((i) => this.dt.row(i).remove());
      this.dt.draw();
    } else {
      location.reload();
    }

    loader.close();
  }
}