import { widget } from "@cems-eco/core/src/utils";
import managementBloc from "../../services/bloc";
import documentBloc from "../../services/document/bloc";
import { Vue } from "vue-class-component";
import { ElLoading } from "element-plus";
import moment from "moment";


export abstract class ManagementTableComponent extends Vue {
  dt:any;
  managementBloc = managementBloc;
  documentBloc = documentBloc;
  options = {}
  modelValue: any;
  headers: any = {
    responseType: "json",
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  async mounted() {
    const loader = ElLoading.service({ lock: true });
    this.updateHeaders();
    await this.builDataTable();
    loader.close();
  }


  async builDataTable() {
    const scope: any = this;
    this.options = {
      serverSide: true,
      ajax: {
        url: this.documentBloc.base_url,
        data: { 'management_id': this.modelValue.id },
        method: 'GET',
        dataType: 'json',
        dataFilter: (data) => {
          const parsedData = JSON.parse(data);
          return JSON.stringify(parsedData.data);
        },
        headers: this.headers,
      },
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
      mobileView: {
          header: {
              actions: [
                  {
                      id: 'downloadAction',
                      template: '<i class="fas fa-file-download text-warning" title="Download" ></i> Download',
                  },
                  {
                      id: 'deleteAction',
                      template: '<i class="far fa-trash-alt text-danger" title="Delete" ></i> Delete',
                  },
              ],
              title: function (data: any) {
                  return data.original_file_name;
              },
          }
      },
      columns: [
        {
          mobileView: true,
          data: 'user.name'
        },
        {
          mobileView: false,
          data: 'original_file_name'
        },
        {
          mobileView: true,
          data: 'description'
        },
        {
          mobileView: true,
          data: 'created_at',
          render: function (data, type, row) {
            return moment(data).format('DD/MM/YYYY h:mm:ss');
          }
        },
        {
          mobileView: false,
          title: "Action",
          data: 'path',
          render: function (data: any, type: any, full: any, meta: any) {
            return (
              '<div class="form-group" style="margin: auto;">' +
              '<div class="btn-group" role="group">' +
              '<a class="btn btn-primary btn-sm download text-white" id="downloadAction" download target="_blank" href="' + `${scope.documentBloc.getDocUrl(data)}` + '"><i class="fas fa-download" data-placement="top" title="Download"></i></a>' +
              `<button type="button" class="btn btn-danger btn-sm delete"id="deleteAction"><i class="far fa-trash-alt" data-placement="top" title="Delete"></i></button>` +
              "</div>" +
              "</div>"
            );
          }
        }
      ],
      createdRow: function (row: any, data: any) {
        $(row).find("#deleteAction")
          .on("click", scope.deleteData.bind(this, data, scope));
      }
    };

  }
  abstract updateHeaders();

  async deleteData(data: any) {
    widget.alertDelete().then(async (result) => {
      try {
        if (!result.isConfirmed) {
          widget.alertSuccess('Deletion abort.', 'Your data is save.')
          return;
        }
        await this.documentBloc.destroyDocument(data.id);
        widget.alertSuccess('Good Job!', 'Your data has been deleted.');
        this.dt.ajax.reload();
      } catch (error) {
        widget.alertError(error);
      }
    })
  }
}