import { Vue } from "vue-class-component";
import { Options } from 'vue-class-component';
import maintenanceBloc from "../../services/bloc";
import moment from "moment";
import { Maintenance } from "../../services";


@Options({
    props: ['modelValue', 'viewOnly'],
    emits: ['update:modelValue']
})
export class MaintenanceDetailComponent extends Vue {
    maintenanceBloc = maintenanceBloc;

    modelValue: any;

    isLoading = true;

    clientOptions: any = null;
    siteOptions: any = null;
    equipmentOptions: any = null;
    userOptions: any = null;
    jobOptions: any = null;
    workProgressOptions: any = null;
    startDatetime: any = null;
    endDatetime: any = null;

    equipmentLoading = false;
    jobLoading = false;
    workProgressLoading = false;

    workProgresses = [
        {
            label: 'Job completed. Test OK.',
            value: '0',
        },
        {
            label: 'Not completed. Required follow-up.',
            value: '1',
        }
    ];

    async created(): Promise<void> {
        this.workProgressOptions = this.workProgresses;
    }

    updateDatetimeInput() {
        if (this.modelValue.start_date && this.modelValue.start_time)
            this.startDatetime = this.modelValue.start_date + ' ' + this.modelValue.start_time, 'YYYY-MM-DD HH:mm';
        if (this.modelValue.end_date && this.modelValue.end_time)
            this.endDatetime = this.modelValue.end_date + ' ' + this.modelValue.end_time, 'YYYY-MM-DD HH:mm';
    }

    startDateDisabled(ts: number) {
        const date = new Date(ts);
        return moment().isBefore(date, 'day');
    }

    startTimeDisabled(ts: number) {
        const date = new Date(ts);
        return {
            isHourDisabled: (hour: number) => {
                if (!moment().isSame(date, 'day')) return false;
                return hour > new Date().getHours();
            },
            isMinuteDisabled: (minute: number, hour: number | null) => {
                if (hour === null) return false;
                if (!moment().isSame(date, 'day')) return false;
                return minute > new Date().getMinutes();
            },
        }
    }

    endDateDisabled(ts: number) {
        const date = new Date(ts);
        return moment(this.modelValue.start_date).isAfter(date, 'day');
    }

    endTimeDisabled(ts: number) {
        const date = new Date(ts);
        return {
            isHourDisabled: (hour: number) => {
                if (moment(this.modelValue.start_date).isBefore(date, 'day')) return false;
                return hour < Number(this.modelValue.start_time.split(':')[0]);
            },
            isMinuteDisabled: (minute: number, hour: number | null) => {
                if (!hour) return true;
                if (moment(this.modelValue.start_date).isBefore(date, 'day')) return false;
                if (hour > Number(this.modelValue.start_time.split(':')[0])) return false;
                return minute < Number(this.modelValue.start_time.split(':')[1]);
            },
        }
    }

    nextServiceDateDisabled(ts: number) {
        const date = new Date(ts);
        return moment(this.modelValue.end_date).isSame(date, 'day') || moment(this.modelValue.end_date).isAfter(date, 'day');
    }

    onStartChanged() {
        this.modelValue.start_date = moment(this.startDatetime ?? '').format('YYYY-MM-DD');
        this.modelValue.start_time = moment(this.startDatetime ?? '').format('HH:mm');

        const initial = new Maintenance;
        this.modelValue.end_date = initial.end_date;
        this.endDatetime = null;
    }

    onEndChanged() {
        this.modelValue.end_date = moment(this.endDatetime ?? '').format('YYYY-MM-DD');
        this.modelValue.end_time = moment(this.endDatetime ?? '').format('HH:mm');
    }

}