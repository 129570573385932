import { Vue } from "vue-class-component";
import { Options } from 'vue-class-component';
import documentBloc from "../../services/document/bloc";
import { ref } from 'vue';
import type { UploadInst, UploadFileInfo } from 'naive-ui'


@Options({
    props: ['modelValue'],
    emits: ['update:modelValue']
})
export class MaintenanceDocumentComponent extends Vue {
    documentBloc = documentBloc;

    modelValue: any;
    accept = [
        ".xls",
        ".xlsx",
        ".png",
        ".jpeg",
        ".jpg",
        ".pdf",
        ".zip",
        ".csv",
        ".txt",
        ".md",
        ".doc",
        ".docx"
    ].toString();

    handleChange(data: { fileList: UploadFileInfo[] }) {
        this.modelValue.original_name = '';

        const fileInfo = data.fileList.at(0);
        if (!fileInfo) return;

        this.modelValue.file = fileInfo.file;
        this.modelValue.original_name = fileInfo.name;
    }
}