import { BoxMonitorApi } from "./api.box";
import { CloudMonitorApi } from "./api.cloud";
import { platform } from "@cems-eco/core/src/utils";
import { BaseMonitorApi } from "./api.base";
import environment from "@/config";

export default platform.service<BaseMonitorApi>(environment.nexus, {
    box: new BoxMonitorApi(),
    cloud: new CloudMonitorApi(),
    demo: new CloudMonitorApi(),
});