import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-12 text-left" }
const _hoisted_3 = { class: "thead-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("div", {
        class: "table-responsive",
        key: _ctx.options
      }, [
        _createVNode(_component_DataTable, {
          class: "table align-items-center table-flush",
          options: _ctx.options,
          id: "report-list"
        }, {
          default: _withCtx(() => [
            _createElementVNode("thead", _hoisted_3, [
              (_openBlock(), _createElementBlock("tr", { key: _ctx.header }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header.label ?? [], (label) => {
                  return (_openBlock(), _createElementBlock("th", {
                    scope: "col",
                    key: label
                  }, _toDisplayString(label), 1))
                }), 128))
              ]))
            ])
          ]),
          _: 1
        }, 8, ["options"])
      ]))
    ])
  ]))
}