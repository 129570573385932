import { Log } from "./index"
import logApi from "./api";
import { ref } from "vue"
import environment from "@/config"

export abstract class BaseLogBloc {
    module = 'log';
    api = logApi;
    context = ref([]);
    base_url = environment.API_URL + '/log';

    async getLogs() : Promise<[Log]> {
        let response = await this.api.all()
        return response.data.data;
    }

    async getLog(id: any): Promise<Log> {
        let response = await this.api.first(id)
        return response.data;
    }

    async createLog(log: Log): Promise<Log> {
        let response = await this.api.create(log)
        return response.data;
    }

    async updateLog(log: Log): Promise<Log> {
        let response = await this.api.edit(log.id, log)
        return response.data;
    }

    // FIXME: not working
    async destroyLog(id: any): Promise<void> {
        let response = await this.api.destroy(id)
        return response.data;
    }

}