import { AlarmFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { debounce } from "lodash";
import { Options } from 'vue-class-component';


@Options({
  emits: ['filter']
})
export default class CloudFilterComponent extends AlarmFilterComponent {
  async mounted() {
    const loader = ElLoading.service({ lock: true });
    await this.buildLevelSelect();
    loader.close();
  }

  async buildLevelSelect() {
    this.levelOptions = this.levels;
  }

  async onTypeSearch(query: string) {
    this.levelLoading = true;
    await debounce(async () => {
      await this.buildLevelSelect();
      this.levelOptions = this.levelOptions.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      );
    }, 250);
    this.levelLoading = false;
  }
}