import { platform } from "@cems-eco/core/src/utils";

import WebSystemComponent from "./system/system.web.vue";
import MobileSystemComponent from "./system/system.mobile.vue";
import CemsInfoComponent from "./info/info.vue";

import WebReadingComponent from "./reading/reading.web.vue";
import MobileReadingComponent from "./reading/reading.mobile.vue";

import AlarmComponent from "./alarm/alarm.vue";

export default platform.factory({
    web: {
        AlarmComponent,
        SystemComponent: WebSystemComponent,
        CemsReading: WebReadingComponent,
        CemsInfoComponent
    },
    mobile: {
        AlarmComponent,
        SystemComponent: MobileSystemComponent,
        CemsReading: MobileReadingComponent,
        CemsInfoComponent
    },
});
