
import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

import { CloudMaintenanceDocumentComponent } from "./document.cloud";
import { DemoMaintenanceDocumentComponent } from "./document.demo";

export default class DocumentComponent extends platform.mixins(environment.nexus, {
    box: CloudMaintenanceDocumentComponent,
    cloud: CloudMaintenanceDocumentComponent,
    demo: DemoMaintenanceDocumentComponent,
}) { }
