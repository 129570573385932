import { ManagementDetailComponent } from "./details";
import { Options } from "vue-class-component";


@Options({
    props: ['modelValue', 'viewOnly'],
    emits: ['update:modelValue']
})
export class CloudManagementDetailComponent extends ManagementDetailComponent {

    async mounted() {
        this.clientOptions = await this.managementBloc.getClientOptions();
        this.isLoading = false;
    }
}