import { ReportFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { Options } from 'vue-class-component';


@Options({
  emits: ['filter']
})
export default class DemoFilterComponent extends ReportFilterComponent {
  private equipments = [
    {
      label: "GAS ANALYZER (6500160)",
      value: "equipment",
    },
  ];
  private types = [
    {
      label: "1 Minute",
      value: "one_minute",
    },
    {
      label: "30 Minute",
      value: "thirty_minute",
    },
    {
      label: "Daily",
      value: "daily",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "JAS",
      value: "jas",
    },
  ];

  async mounted() {
    const loader = ElLoading.service({ lock: true });
    await this.buildEquipmentSelect();
    await this.buildReadingTypeSelect();
    loader.close();
  }

  async buildEquipmentSelect() {
    this.equipmentOptions = this.equipments;
  }

  async buildReadingTypeSelect() {
    this.typeOptions = this.types;
  }

  async onEquipmentSearch(query: string) {
    if (!query.length) {
      this.equipmentOptions = []
      return
    }
    this.equipmentLoading = true
    window.setTimeout(() => {
      this.equipmentOptions = this.equipments.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.equipmentLoading = false
    }, 1000)
  }

  async onTypeSearch(query: string) {
    if (!query.length) {
      this.types = []
      return
    }
    this.typeLoading = true
    window.setTimeout(() => {
      this.types = this.types.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.typeLoading = false
    }, 1000)
  }
}