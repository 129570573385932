import { MaintenanceDetailComponent } from "./details";
import { ElLoading } from "element-plus";
import { Subscription } from 'rxjs';
import { debounce } from "lodash";


export class CloudMaintenanceDetailComponent extends MaintenanceDetailComponent {
    async mounted() {
        const loader = ElLoading.service({ lock: true });

        this.clientOptions = await this.maintenanceBloc.getClientOptions();
        this.siteOptions = await this.maintenanceBloc.getSiteOptions();
        this.userOptions = await this.maintenanceBloc.getUserOptions();
        this.jobOptions = await this.maintenanceBloc.getJobOptions();
        this.equipmentOptions = await this.maintenanceBloc.getEquipmentOptionsBySite(this.modelValue.site_id);

        setTimeout(() => {
            this.updateDatetimeInput();
        }, 500);

        loader.close();
        this.isLoading = false;
    }

    async onEquipmentSearch(query: string) {
        this.equipmentLoading = true;

        await debounce(async () => {
            this.equipmentOptions = await this.maintenanceBloc.getEquipmentOptionsBySite(this.modelValue.site_id);
            this.equipmentOptions = this.equipmentOptions.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            );
        }, 250);

        this.equipmentLoading = false;
    }

    async onJobSearch(query: string) {
        this.jobLoading = true;

        await debounce(async () => {
            this.jobOptions = await this.maintenanceBloc.getJobOptions();
            this.jobOptions = this.jobOptions.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            )
        }, 250);

        this.jobLoading = false;
    }

    async onWorkProgressSearch(query: string) {
        this.workProgressLoading = true
        window.setTimeout(() => {
            this.workProgressOptions = this.workProgresses.filter(
                (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
            )
            this.workProgressLoading = false
        }, 1000)
    }
}