import { BoxMonitorBloc } from "./bloc.box";
import { CloudMonitorBloc } from "./bloc.cloud";
import { platform } from "@cems-eco/core/src/utils";
import { BaseMonitorBloc } from "./bloc.base";
import environment from "@/config";

export default platform.service<BaseMonitorBloc>(environment.nexus, {
    box: new BoxMonitorBloc(),
    cloud: new CloudMonitorBloc(),
    demo: new CloudMonitorBloc(),
});