import { platform } from "@cems-eco/core/src/utils";
import DetailsComponent from "./details/details.vue";
import TableComponent from "./table/table.vue";
import WebDocumentComponent from "./document/document.web.vue";
import MobileDocumentComponent from "./document/document.mobile.vue";


export default platform.factory({
    web: {
        DetailsComponent,
        TableComponent,
        DocumentComponent: WebDocumentComponent,
    },
    mobile: {
        DetailsComponent,
        TableComponent,
        DocumentComponent: MobileDocumentComponent,
    },
}); 
