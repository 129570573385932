import { AlarmFilter } from "../../services";
import monitorBloc from "../../services/bloc";
import { Vue } from "vue-class-component";
import formatter from "@cems-eco/core/src/services/formatter/formatter";
import { ElLoading } from "element-plus";


export abstract class BaseAlarmComponent extends Vue {

  monitorBloc = monitorBloc
  options = {}
  dt: any;
  equipmentId: any;
  alarmFilter: AlarmFilter
  isSearching = false;
  headers: any = {
    responseType: "json",
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  async mounted() {
    const loader = ElLoading.service({ lock: true });
    this.updateHeaders();
    await this.builDataTable();
    loader.close();
  }

  updateHeaders() {};

  async builDataTable() {
    const scope: any = this;
    const url = await this.monitorBloc.getLogUrlByEquipment(this.equipmentId);

    this.options = {
      pageLength: 5,
      lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
      serverSide: true,
      ajax: {
        url: url,
        method: 'GET',
        dataType: 'json',
        headers: this.headers,
        dataFilter: (data) => {
          const parsedData = JSON.parse(data);
          return JSON.stringify(parsedData.data);
        },
      },
      columnDefs: [
        {
          "targets": -1,
          "width": "1%"
        },
      ],
      mobileView: {
        header: {
          title: function (row: any) {
            return row.message;
          },
        }
      },
      columns: [
        {
          mobileView: true,
          title: "Message",
          data: "message",
        },
        {
          mobileView: false,
          title: "Timestamp",
          data: "created_at",
          render: function (id: any, type: any, full: any, meta: any) {
            return formatter.medium(full.created_at);
          }
        },
      ],
    };
  }

  getFilter(filter: any) {
      this.alarmFilter = filter;
      this.searching();
  }

  searching() { };

  dtInstance(dt) {
    this.dt = dt;
  }

}