import { MaintenanceFilterComponent } from "./filter";
import { ElLoading } from "element-plus";
import { Options } from 'vue-class-component';


@Options({
  emits: ['filter']
})
export default class DemoFilterComponent extends MaintenanceFilterComponent {
  private equipments = [
    {
      label: "GAS ANALYZER (6500160)",
      value: "equipment",
    },
  ];
  private users = [
    {
      label: "Shahrin Nidzam",
      value: "user",
    },
  ];
  private jobs = [
    {
      label: "Preventive Maintenance",
      value: "1",
    },
    {
      label: "Replacement",
      value: "2",
    },
    {
      label: "Installation",
      value: "3",
    },
    {
      label: "Site Survey",
      value: "4",
    },
    {
      label: "Project",
      value: "5",
    },
    {
      label: "Commissioning",
      value: "6",
    },
    {
      label: "Breakdown Service",
      value: "7",
    },
    {
      label: "Training",
      value: "8",
    },
    {
      label: "Sales Support",
      value: "9",
    },
  ];

  async mounted() {
    const loader = ElLoading.service({ lock: true });

    this.userOptions = this.users;
    this.equipmentOptions = this.equipments;
    this.jobOptions = this.jobs;

    loader.close();
  }

  async onUserSearch(query: string) {
    this.userLoading = true
    window.setTimeout(() => {
      this.userOptions = this.users.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.userLoading = false
    }, 1000)
  }

  async onEquipmentSearch(query: string) {
    this.equipmentLoading = true
    window.setTimeout(() => {
      this.equipmentOptions = this.equipments.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.equipmentLoading = false
    }, 1000)
  }

  async onJobSearch(query: string) {
    this.jobLoading = true
    window.setTimeout(() => {
      this.jobs = this.jobs.filter(
        (item) => ~item.label.toLowerCase().indexOf(query.toLowerCase())
      )
      this.jobLoading = false
    }, 1000)
  }
}