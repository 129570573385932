import { Management } from "./index";
import managementApi from "./api";
import { Observable } from "rxjs"
import { ISiteStorage } from "@/storage/model"
import storage from "@/storage"
import { startWith } from "rxjs/operators"
import { Client } from "@/modules/maintenance/services";

export abstract class BaseManagementBloc {
    module = 'management';
    api = managementApi;

    async getManagements() : Promise<[Management]> {
        let response = await this.api.all()
        return response.data.data;
    }

    async getManagement(id: any): Promise<Management> {
        let response = await this.api.first(id)
        return response.data;
    }

    async getManagementByClient(id: any): Promise<Management> {
        let response = await this.api.getManagementByClient(id)
        return response.data;
    }

    async createManagement(management: Management): Promise<Management> {
        let response = await this.api.create(management)
        return response.data;
    }

    async updateManagement(management: Management): Promise<Management> {
        let response = await this.api.edit(management.id, management)
        return response.data;
    }

    // FIXME: not working
    async destroyManagement(id: any): Promise<void> {
        let response = await this.api.destroy(id)
        return response.data;
    }

    async getClient(id: any): Promise<Client> {
        let response = await this.api.getClientById(id)
        return response.data;
    }


    async getSiteStorage(): Promise<Observable<ISiteStorage>> {
        const siteStorage: ISiteStorage = await storage.get<ISiteStorage>(storage.SITE);
        return storage.observable<ISiteStorage>(storage.SITE).pipe(startWith(siteStorage));
    }

    async getClientSelect2Url(): Promise<string> {
        return await this.api.getUrl('/client/select2');
    }

    async getClientOptions(): Promise<any[]> {
        return await this.api.getOptions('/client/select2');
    }
}

