import { api } from '@cems-eco/core/src/services/api';
import { helper } from '@cems-eco/core/src/utils';
import { ReportFilter, ReportFormat } from './index';

export abstract class BaseReportApi {
    BASE_URL: string;


    constructor() {
        this.BASE_URL = "/report";
    }

    async allByEquipment(equipmentId: any): Promise<any> {
        const url = this.BASE_URL + '/equipment/' + equipmentId + '/30m';
        return await api.get<any>(url, true);
    }

    async header(reportFilter: ReportFilter): Promise<any> {
        const url = this.BASE_URL + '/header';
        return await api.post<any>(url, reportFilter, true);
    }

    async allByReportFilter(reportFilter: ReportFilter, format: ReportFormat, additionalParam: any = {}): Promise<any> {
        let uri = helper.toSerializeDatatable(reportFilter);
        if (Object.keys(additionalParam).length) {
            uri = uri + `&${api.toSerialize(additionalParam)}`;
            const url = this.BASE_URL + '/' + format.toString() + encodeURI(uri);

            return await api.get<any>(url, true);
        } else {
            const url = this.BASE_URL + '/' + format.toString() + encodeURI(uri);
            return await api.get<any>(url, true);
        }
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async create(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async edit(id: any, data: any): Promise<any> {
        return await api.put<any>(`${this.BASE_URL}/${id}`, data, true);
    }

    async destroy(id: any): Promise<any> {
        return await api.delete<any>(`${this.BASE_URL}/${id}`, true);
    }

    async download(reportFilter: ReportFilter, format: ReportFormat, fileName: string): Promise<any> {
        const uri = helper.toSerializeDatatable(reportFilter);
        const url = this.BASE_URL + '/' + format.toString() + encodeURI(uri);
        return await api.downLoadFile<any>(url, fileName, true);
    }

    async sensorByEquipment(equipment_id: any): Promise<any> {
        return await api.get<any>(`/sensor/select2/equipment/${equipment_id}`, true);
    }

    async getUrl(url): Promise<string> {
        return await api.getUrl(url);
    }

    async getOptions(url, keyValue = 'id'): Promise<any[]> {
        let response = await api.get<any>(url, true);
        return (response.data as any[]).map((e) => ({
            label: e.text,
            value: e[keyValue],
        }));
    }

}
