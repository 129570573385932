
import storage from "@/storage";
import { ReportFilter, ReportFormat } from "../../services";
import { ReportTableComponent } from "./table";
import { Options } from "vue-class-component";


@Options({
  props: {
    reportFilter: ReportFilter
  },
})
export default class CloudTableComponent extends ReportTableComponent {

  updateHeaders() {
    let auth = storage.getSync<any>(storage.SITE_AUTH);
    if (auth) {
      this.headers.Authorization = `Bearer ${auth["access_token"]}`;
    }
  }

}